<template>
  <div>
    <modal className="w-full md:w-6/10 lg:w-6/10 xl:w-4/10 pt-10 px-10" ref="modal">
      <template v-if="selectedUser">
        <section class="mb-5">
          <div class="flex flex-row items-center justify-between mb-1">
            <div class="flex flex-row items-center">
              <h2 class="text-2xl font-bold mr-2">
                {{ selectedUser.name | sentenceCase }} {{ selectedUser.last_name | sentenceCase }}
              </h2>
            </div>
          </div>

          <div class="text-sm text-gray-500 mb-5">
            {{ position | sentenceCase }}
          </div>

          <div>
            <!-- Profile Not Completed -->
            <div
              class="alert alert-blue items-start mb-2"
              v-if="!userProfileCompleted"
            >
              <span class="alert-icon">!</span>
              <span>
                <span class="block font-bold">Incomplete Profile</span>
                <span class="block font-light">
                  {{ firstName }} hasn't yet completed their profile.
                </span>
              </span>
            </div>
            <!-- Profile Not Completed -->
            <!-- Profile Completed -->
            <div
              class="alert alert-blue items-start mb-2"
              v-else-if="userApprovalPending"
            >
              <span class="alert-icon">!</span>
              <span>{{ firstName }}'s profile needs activation.</span>
            </div>
            <!-- Profile Completed -->
          </div>

          <div class="flex flex-wrap gap-2 items-center">
            <router-link
              :to="{ name: 'staff-view', params: { staffId: selectedUser.id }, query }"
              target="_blank"
              class="btn btn-blue"
            >
              <ion-icon name="body-outline" class="text-xl"></ion-icon>
              <span class="ml-2">View Profile</span>
            </router-link>

            <button
              v-if="canBeginInstantVerification"
              class="btn btn-green"
              :disabled="loanbotForm.loading || deleteForm.loading"
              @click.prevent="confirmInstantVerification"
            >
              <sm-loader-white v-if="loanbotForm.loading" />
              <template v-else>
                <ion-icon name="flash-outline" class="text-xl" />
                <span class="ml-2">Instant Verification</span>
              </template>
            </button>

            <button
              v-if="canDeleteUser"
              class="btn btn-red"
              :disabled="loanbotForm.loading || deleteForm.loading"
              @click.prevent="confirmDelete"
            >
              <sm-loader-white v-if="deleteForm.loading" />
              <template v-else>
                <ion-icon name="trash-outline" class="text-xl" />
                <span class="ml-2">Delete</span>
              </template>
            </button>
          </div>
        </section>

        <template v-if="getFormError(loanbotForm)">
          <div class="alert alert-red-soft mb-5">
            <span class="alert-icon">
              <ion-icon name="alert-outline" />
            </span>
            <span>
              {{ getFormError(loanbotForm) }}
            </span>
          </div>
        </template>

        <section class="mb-5">
          <div class="mb-2 text-gray-500">Profile:</div>

          <div class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200">
            <div class="col-span-1 p-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                {{ isEmployed ? 'Company' : 'Business Name' }}
              </div>
              <div
                class="text-gray-500 text-sm font-light flex flex-row items-center"
              >
                <ion-icon name="wallet-outline" class="text-lg mr-2"></ion-icon>
                {{ company }}
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200 -mt-px">
            <div class="col-span-1 p-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                {{ isEmployed ? 'Salary' : 'Monthly Income' }}
              </div>
              <div
                class="text-gray-500 text-sm font-light flex flex-row items-center"
              >
                <ion-icon name="wallet-outline" class="text-lg mr-2"></ion-icon>
                {{ salary | currency }}
              </div>
            </div>
            <div
              class="col-span-1 p-4 border-t sm:border-t-0 sm:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">
                Job Title
              </div>
              <div
                class="text-gray-500 text-sm font-light flex flex-row items-center"
              >
                <ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
                {{ position }}
              </div>
            </div>
          </div>

          <div
            class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200 -mt-px"
          >
            <div class="col-span-1 p-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                Phone Number
              </div>
              <div
                class="text-gray-500 text-sm font-light flex flex-row items-center"
              >
                <ion-icon name="call-outline" class="text-lg mr-2"></ion-icon>
                {{ phoneNumber }}
              </div>
            </div>
            <div
              class="col-span-1 p-4 border-t sm:border-t-0 sm:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">
                Email
              </div>
              <div
                class="text-gray-500 text-sm font-light flex flex-row items-center"
              >
                <ion-icon name="mail-outline" class="text-lg mr-2"></ion-icon>
                {{ email }}
              </div>
            </div>
          </div>

          <div
            class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200 -mt-px"
          >
            <div class="col-span-1 p-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                Employment Status
              </div>
              <div
                class="text-gray-500 text-sm font-light flex flex-row items-center"
              >
                <ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
                {{ employmentStatus | fromSlug }}
              </div>
            </div>
            <div
              class="col-span-1 p-4 border-t sm:border-t-0 sm:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">
                Source
              </div>
              <div
                class="text-gray-500 text-sm font-light flex flex-row items-center"
              >
                <ion-icon name="arrow-redo-outline" class="text-lg mr-2"></ion-icon>
                {{ source | sentenceCase }}
              </div>
            </div>
          </div>

          <!-- <div
            class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200 -mt-px"
          >
            <div class="col-span-1 p-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                Total Loan Amount
                </div>
              <div
                class="text-gray-500 text-sm font-light flex flex-row items-center"
              >
                <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
                ₦ 0.00
              </div>
            </div>
            <div
              class="col-span-1 p-4 border-t sm:border-t-0 sm:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">
                Current Loan
                </div>
              <div
                class="text-gray-500 text-sm font-light flex flex-row items-center"
              >
                <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
                ₦ 0.00
              </div>
            </div>
          </div> -->
        </section>

        <section
          class="mb-5"
          v-if="canViewAssignments"
        >
          <div class="mb-2 text-gray-500">Assignments:</div>

          <div class="border border-blue-200">
            <datatable
              :data="admins"
              :columns="adminColumns"
              :actions="adminActions"
              :dropdown="false"
              :header="false"
              :fillable="false"
              :min-height="false"
            />
          </div>
        </section>
      </template>
      <div>
        <button type="button" class="btn btn-blue" @click="close">
          Close
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 p-10"
      ref="deleteConfirmModal"
      @close="deleteConfirmModalClosed"
    >
      <div class="mb-10">
        <div class="text-xl font-bold mb-10">
          Delete This User?
        </div>

        <div class="font-light text-gray-500 text-sm">
          <p>
            This user will be removed from the plaform and his information disregarded.
          </p>
          <p class="mb-2">
            The user will have to be registered to regain access to CredPal.
          </p>
        </div>
      </div>

      <template v-if="getFormError(deleteForm)">
        <div class="alert alert-red items-start mb-10">
          <span class="alert-icon">!</span>
          <span class="flex flex-col">
            <span class="font-bold">Unable to delete user!</span>
            <span class="font-light">{{ getFormError(deleteForm) }}</span>
          </span>
        </div>
      </template>

      <div class="flex items-center">
        <button
          type="button"
          class="btn btn-blue-soft"
          @click.prevent="$refs.deleteConfirmModal.close"
          :disabled="deleteForm.loading"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn-red ml-3"
          @click.prevent="deleteUser"
          :disabled="deleteForm.loading"
        >
          <sm-loader class="sm-loader-white" v-if="deleteForm.loading" />
          <span v-else>Proceed</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="deleteSuccessModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      >
      <div class="text-lg font-bold mb-4">
        Delete!
      </div>
      <div class="text-xs mb-10">
        User Deleted Successfully.
      </div>

      <button
        type="button"
        class="btn btn-blue"
        @click.prevent="$refs.deleteSuccessModal.close"
      >
        Okay
      </button>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10"
      ref="unassignConfirmModal"
      @close="unassignConfirmModalClosed"
    >
      <div class="mb-10">
        <div class="text-xl font-bold mb-10">
          Delete This User?
        </div>

        <div class="font-light text-gray-500 text-sm">
          <p class="mb-2">
            This will also clear the status of this assignment.
          </p>
        </div>
      </div>

      <template v-if="getFormError(unassignForm)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert icon">!</span>
          <span>{{ getFormError(unassignForm) }}</span>
        </div>
      </template>

      <div class="flex items-center">
        <button
          type="button"
          class="btn btn-blue"
          @click.prevent="$refs.unassignConfirmModal.close"
          :disabled="unassignForm.loading"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn-red ml-3"
          @click.prevent="deleteUser"
          :disabled="unassignForm.loading"
        >
          <sm-loader class="sm-loader-white" v-if="unassignForm.loading" />
          <span v-else>Proceed</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="deleteSuccessModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      >
      <div class="text-lg font-bold mb-4">
        Delete!
      </div>
      <div class="text-xs mb-10">
        User Deleted Successfully.
      </div>

      <button
        type="button"
        class="btn btn-blue"
        @click.prevent="$refs.deleteSuccessModal.close"
      >
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
  export default {
    props: {
      allowDelete: {
        type: Boolean,
        default: true,
      },
      loanbotVerification: {
        type: Object,
        default: null,
      },
      selectedUser: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        closeTimeout: null,
        deleteForm: this.$options.basicForm([]),
        unassignForm: this.$options.basicForm([]),
        loanbotForm: this.$options.basicForm([]),
        adminColumns: [
          {
            className: 'w-full',
            name: 'name',
            th: 'Admin',
            render: user => `${user.name} ${user.last_name}`
          },
          {
            name: 'status',
            th: 'Status',
            render: (admin) => {
              switch (admin?.pivot?.status) {
                case 'attended':
                return `
                  <div class="text-xs text-green-700 flex items-center">
                    <ion-icon name="checkmark-done-outline" class="mr-2"></ion-icon>
                    <span style="margin-top: 2px">Attended</span>
                  </div>
                  `;
                case 'contacted':
                return `
                  <div class="text-xs text-blue-700 flex items-center">
                    <ion-icon name="checkmark-outline" class="mr-2"></ion-icon>
                    <span style="margin-top: 2px">Contacted</span>
                  </div>
                  `;
                case 'pending':
                default:
                return `
                  <div class="text-xs text-orange-700 flex items-center">
                    <ion-icon name="remove-circle-outline" class="mr-2"></ion-icon>
                    <span style="margin-top: 2px">Pending</span>
                  </div>
                  `;
              }
            }
          }
        ],
        adminActions: [
          {
            text: 'Unassign',
            class: 'btn btn-red btn-sm',
            disabled: admin => admin.pivot?.status === 'pending'
          }
        ],
      }
    },
    computed: {
      admins() {
        return this.selectedUser?.admins;
      },
      canBeginInstantVerification() {
        return this.canDo('credpal:can_use_loanbot_verifications') && this.loanbotVerification?.status === 'failed';
      },
      canDeleteUser() {
        return this.canDo('credpal:can_delete_users') && this.allowDelete;
      },
      canViewAssignments() {
        return this.canDo('credpal:can_view_assignments') && this.admins?.length;
      },
      company () {
        return this.selectedUser?.profile?.employer || this.selectedUser?.company?.name || 'Not Employed';
      },
      email() {
        return this.selectedUser?.email;
      },
      employmentStatus() {
        return this.selectedUser?.profile?.employment_status || 'N/A';
      },
      firstName() {
        return this.selectedUser?.name;
      },
      isEmployed() {
        return this.employmentStatus == 'employed';
      },
      phoneNumber() {
        return this.selectedUser?.phone_no;
      },
      position() {
        return this.selectedUser?.profile?.position || 'CredPal User';
      },
      query() {
        const query = {};
        if (this.$route.name?.match(/users-(processing|processed|approved)/)) {
          query.department = 'risk';
        }

        if (this.$route.name?.match(/^users$/)) {
          query.department = 'sales';
        }

        return query;
      },
      salary() {
        return this.selectedUser?.profile?.salary || 0;
      },
      source() {
        return this.selectedUser?.source;
      },
      userProfileCompleted() {
        return this.selectedUser?.profile;
      },
      userApprovalPending() {
        return this.selectedUser?.profile?.status === 'pending';
      },
    },
    methods: {
      close() {
        if (this.$refs.modal.isOpen) {
          this.$refs.modal.close();
        }

        this.closeTimeout = setTimeout(() => {
          this.$emit('close');
        }, 1000);
      },
      open() {
        clearTimeout(this.closeTimeout);
        this.$refs.modal.open();
        this.$emit('open')
      },

      confirmDelete() {
        this.$refs.deleteConfirmModal.open();
      },
      confirmInstantVerification() {
        this.$alert({
          title: 'Instant Verification',
          body: 'This process will attempt to underwrite and verify a user automatically.',
          subBody: 'Do you wish to proceed?',
          buttons: [
            {
              text: 'No',
              className: 'btn-blue-soft',
            },
            {
              text: 'Yes',
              className: 'btn-blue',
              onClick: () => this.beginInstantVerification(),
            },
          ],
        });
      },
      deleteConfirmModalClosed() {
        if (this.deleteForm.loading) {
          this.$refs.deleteConfirmModal.open();
        }
      },
      confirmUnassignment() {
        this.$refs.unassignConfirmModal.open();
      },
      unassignConfirmModalClosed() {
        if (this.unassignForm.loading) {
          this.$refs.unassignConfirmModal.open();
        }
      },
      async beginInstantVerification () {
        this.loanbotForm.error = false;
        this.loanbotForm.loading = true;
        await this.sendRequest('admin.users.loanbot.instantApproval', this.selectedUser.id, {
          success: (response) => {
            const { data: { user, profile, verification } } = response.data;
            switch (user.loanbot_status) {
              case 'approved':
                this.$success({
                  title: 'Instant Approval Successful',
                  body: `User approved for ${profile.credit_limit} credit`,
                  button: 'Okay',
                });
                break;
              case 'declined':
                this.$error({
                  title: 'User Application Declined',
                  body: verification.remark,
                  button: 'Okay',
                });
                break;
              case 'failed':
                this.$error({
                  title: 'Instant Approval Failed',
                  body: 'An error occurred on the server.',
                  button: 'Okay',
                });
                break;
              case 'undecided':
              default:
                this.$alert({
                  title: 'Undecided',
                  body: verification.remark,
                  button: 'Okay',
                });
                break;
            }
            this.close();
            this.$emit('update', this.selectedUser);
          },
          error: error => {
            this.loanbotForm.error = error;
          }
        }).finally(() => {
          this.loanbotForm.loading = false;
        });
        this.loanbotForm.loading = false;
      },
      async deleteUser() {
        this.deleteForm.error = false;
        this.deleteForm.loading = true;
        await this.sendRequest('admin.users.delete', this.selectedUser.id, {
          success: () => {
            this.$emit('delete', this.selectedUser);
            this.$success({
              title: 'Operation Completed',
              body: 'User deleted successfully.',
              button: 'Okay',
            });
            this.close();
            setTimeout(() => {
              this.$refs.deleteConfirmModal.close();
            }, 100);
          },
          error: error => {
            this.deleteForm.error = error;
          }
        });
        this.deleteForm.loading = false;
      },
      async unassignUser() {
        this.deleteForm.error = false;
        this.deleteForm.loading = true;
        await this.sendRequest('admin.users.delete', this.selectedUser.id, {
          success: () => {
            this.$emit('delete', this.selectedUser);
            this.$success({
              title: 'Operation Completed',
              body: 'Assignments removed.',
              button: 'Okay',
            });
            this.close();
            setTimeout(() => {
              this.$refs.unassignConfirmModal.close();
            }, 100);
          },
          error: error => {
            this.deleteForm.error = error;
          }
        });
        this.deleteForm.loading = false;
      },
    }
  }
</script>
